import React, { ReactElement } from 'react';
import { VStack, DrawerHeader, HStack, Text } from '@wegroup/design-system';

interface Props {
  title?: string;
  description?: string;
  icon?: ReactElement;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
}

const DrawerGeneralHeader: React.FC<Props> = ({
  title,
  description,
  icon,
  rightElement,
  leftElement,
}) => {
  return (
    <DrawerHeader
      as={HStack}
      borderBottom="1px"
      borderColor="gray.50"
      py="4"
      px="4"
      spacing="2"
    >
      {icon}
      {leftElement}
      <VStack spacing="1" flexGrow="1" align="stretch">
        {title && <Text textStyle="normalBodyMedium">{title}</Text>}
        {description && (
          <Text textStyle="smallBody" color="gray.500">
            {description}
          </Text>
        )}
      </VStack>
      {rightElement}
    </DrawerHeader>
  );
};

export default DrawerGeneralHeader;
