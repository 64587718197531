import { useQuery } from 'react-query';
import { InsuranceCompany } from '../../../../../../types/Broker';
import { wgApi } from '../../../../../app/src/features/api/wgApi';

export interface InsuranceCompaniesResp {
  items: { insurance_company: InsuranceCompany }[];
}

export const getAvailableInsuranceCompanies = async () => {
  const { data, isError } = await wgApi.get<InsuranceCompaniesResp>(
    `v1/api/broker/v1/api/insurance_companies?pagelen=100`,
  );
  if (isError) throw new Error();
  return data;
};

const getUseAvailableInsuranceCompaniesQKey = () => [
  'sales-conversation',
  'insurance-companies',
];

export const useAvailableInsuranceCompanies = () => {
  return useQuery(
    getUseAvailableInsuranceCompaniesQKey(),
    getAvailableInsuranceCompanies,
    { staleTime: Infinity },
  );
};
