import React, { RefObject, useMemo, useRef } from 'react';
import {
  Box,
  Text,
  useMediaQuery,
  useOutsideClick,
} from '@wegroup/design-system';
import SearchPickerOption from './SearchPickerOption';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import SearchPickerCategories from '../SearchPickerInput/SearchPicker/SearchPickerCategories';
import SearchPickerHeader from './SearchPickerHeader';
import { isEqual } from 'lodash';

export interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
  img?: string;
  isDisabled?: boolean;
}

export interface Category {
  label: string;
  option_keys: string[];
}

interface Props {
  options: Option[];
  categories: Category[];
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  noResultsText?: string;
  hideIcons?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  withSelectAll?: boolean;
  withCloseButton?: boolean;
  onClosePopover?: () => void;
  isDisabled?: boolean;
}

const SearchPickerList: React.FC<React.PropsWithChildren<Props>> = ({
  hideIcons,
  options,
  categories,
  selectedOptions,
  setSelectedOptions,
  noResultsText,
  inputRef,
  withSelectAll,
  withCloseButton,
  onClosePopover,
  isDisabled,
}) => {
  const parentRef = useRef(null);
  const { t } = useTranslation();
  const optionsWithoutCategories = useMemo(
    () =>
      options.filter(
        (o) => !categories.some((c) => c.option_keys.includes(o.value)),
      ),
    [categories, options],
  );
  const isCategorised = optionsWithoutCategories.length === options.length;
  const [bigHeight] = useMediaQuery(`(min-height: 500px`, { ssr: false });
  const rowVirtualizer = useVirtual({
    size: optionsWithoutCategories.length,
    parentRef,
  });

  useOutsideClick({
    ref: parentRef,
    handler: () => onClosePopover?.(),
  });

  const handleOptionClick = (option: Option) => {
    if (selectedOptions.some((o) => o.value === option.value)) {
      // remove it from selectedOptions
      setSelectedOptions([
        ...selectedOptions.filter((o) => o.value !== option.value),
      ]);
    } else {
      // add it to selectedOptions
      setSelectedOptions([...selectedOptions, option]);
    }
    inputRef?.current?.focus();
  };

  return (
    <Box
      h="100%"
      maxH={{ base: '100%', tabletS: bigHeight ? '80' : '60vh' }}
      overflow="auto"
      sx={{ ...chakraCustomScrollBar }}
      data-test-id="General_SearchPickerList_List"
      background="white"
      ref={parentRef}
    >
      <SearchPickerHeader
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        withSelectAll={withSelectAll}
        withCloseButton={withCloseButton}
        onClose={onClosePopover}
      />
      {/* Show category options */}
      {categories.length > 0 && (
        <SearchPickerCategories
          categories={categories}
          options={options}
          selectedOptions={selectedOptions}
          handleOptionClick={handleOptionClick}
          virtualItems={rowVirtualizer.virtualItems}
          hideIcons={hideIcons}
        />
      )}
      {/* Show "other" options */}
      {!isCategorised && optionsWithoutCategories.length > 0 && (
        <Box bg="gray.strokeAndBg" py="2" px="3">
          <Text textStyle="smallBodyMedium" color="gray.400">
            {t('Other')}
          </Text>
        </Box>
      )}
      {optionsWithoutCategories.length > 0 && (
        <Box h={rowVirtualizer.totalSize} w="100%" position="relative">
          {rowVirtualizer.virtualItems.map(({ index, measureRef, start }) => {
            const option = optionsWithoutCategories[index];
            return (
              <Box
                key={index}
                ref={measureRef}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                transform={`translateY(${start}px)`}
              >
                <SearchPickerOption
                  option={option}
                  selected={selectedOptions.some((o) =>
                    isEqual(o.value, option.value),
                  )}
                  onClick={() => handleOptionClick(option)}
                  hideIcon={hideIcons}
                  isDisabled={option.isDisabled}
                />
              </Box>
            );
          })}
        </Box>
      )}
      {options.length === 0 && (
        <Text px="3" py="4" textStyle="normalBody">
          {noResultsText || t('No results')}
        </Text>
      )}
    </Box>
  );
};

export default SearchPickerList;
