import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPolitics: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5849_115"
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill="#C0C2C9" d="M0-.002h356v228H0z"></path>
      </mask>
      <g mask="url(#mask0_5849_115)">
        <path
          fill={colors.primary[50]}
          d="M337 160.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160"
        ></path>
        <path
          fill={colors.primary[50]}
          d="m205.872 42.517-16.706-3.147a2 2 0 0 0-2.336 1.595l-2.314 12.284 18.672 3.517a2 2 0 0 0 2.336-1.595l1.943-10.319a2 2 0 0 0-1.595-2.335"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeWidth="3"
          d="m181 71.921 3.516-18.672m0 0 2.314-12.284a2 2 0 0 1 2.336-1.595l16.706 3.147a2 2 0 0 1 1.595 2.335l-1.943 10.319a2 2 0 0 1-2.336 1.595z"
        ></path>
        <path
          fill={colors.primary[50]}
          d="m150.965 42.517 16.707-3.147a2 2 0 0 1 2.335 1.595l2.314 12.284-18.672 3.517a2 2 0 0 1-2.335-1.595l-1.944-10.319a2 2 0 0 1 1.595-2.335"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeWidth="3"
          d="m175.838 71.921-3.517-18.672m0 0-2.314-12.284a2 2 0 0 0-2.335-1.595l-16.707 3.147a2 2 0 0 0-1.595 2.335l1.944 10.319a2 2 0 0 0 2.335 1.595z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="m294.752 129.444-25.216-60.548c-.323-.778-.942-1.455-1.769-1.934-.828-.48-1.822-.738-2.842-.737H94.507c-1.02 0-2.015.257-2.842.737-.828.48-1.447 1.156-1.77 1.933l-25.193 60.549a3.2 3.2 0 0 0-.196 1.825c.116.612.411 1.194.862 1.696a4.7 4.7 0 0 0 1.73 1.193 5.8 5.8 0 0 0 2.215.428h220.809a5.9 5.9 0 0 0 2.22-.425 4.7 4.7 0 0 0 1.734-1.192 3.53 3.53 0 0 0 .867-1.697 3.2 3.2 0 0 0-.191-1.828"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M67.914 134.596h223.603v60.567H67.914z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M179.717 161.947h21.875v33.213h-21.875z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M157.843 161.947h21.875v33.213h-21.875zM201.592 161.947h-43.749v-14.704c0-.815.403-1.596 1.12-2.173.717-.576 1.69-.9 2.704-.9h36.102c1.014 0 1.986.324 2.703.9s1.12 1.358 1.12 2.173zM99.512 154.131h19.442v25.399H99.512z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M123.818 151.986c0 1.401-1.415 2.151-3.159 2.151H97.806c-1.743 0-3.154-.744-3.154-2.151v-5.084c0-.333.08-.663.239-.971a2.6 2.6 0 0 1 .683-.824c.293-.236.641-.423 1.024-.55a3.8 3.8 0 0 1 1.208-.194h22.853c.838 0 1.641.268 2.234.744.592.476.925 1.122.925 1.795z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M94.652 179.531h29.166"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M240.477 154.131h19.443v25.399h-19.443z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M264.784 151.986c0 1.401-1.415 2.151-3.159 2.151h-22.849c-1.744 0-3.158-.744-3.158-2.151v-5.084c0-.673.332-1.319.925-1.795.592-.476 1.395-.744 2.233-.744h22.849c.838 0 1.641.268 2.234.744.592.476.925 1.122.925 1.795z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.618 179.531h29.166"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M140.828 102.032a3.26 3.26 0 0 0-.457-1.656 4.15 4.15 0 0 0-1.29-1.344l-16.059-10.42c-.873-.584-1.973-.905-3.11-.905s-2.238.32-3.111.906l-15.558 10.42a4.16 4.16 0 0 0-1.288 1.343 3.3 3.3 0 0 0-.459 1.656v18.881a3.25 3.25 0 0 0 .366 1.502c.244.477.603.91 1.056 1.274.453.365.992.654 1.584.85a5.9 5.9 0 0 0 1.869.295h31.598c1.289 0 2.525-.411 3.436-1.144s1.423-1.726 1.423-2.762z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M128.678 124.835h-17.015v-18.426c0-.815.403-1.596 1.12-2.173.717-.576 1.689-.9 2.703-.9h9.368c1.014 0 1.987.324 2.704.9s1.12 1.358 1.12 2.173z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M259.923 102.03a3.3 3.3 0 0 0-.459-1.656 4.15 4.15 0 0 0-1.292-1.343l-16.059-10.42c-.873-.585-1.974-.906-3.112-.906-1.137 0-2.239.32-3.112.906l-15.554 10.42a4.15 4.15 0 0 0-1.292 1.343 3.3 3.3 0 0 0-.459 1.656v18.881a3.2 3.2 0 0 0 .367 1.503c.245.477.604.91 1.058 1.275a5.1 5.1 0 0 0 1.587.849 5.9 5.9 0 0 0 1.87.295h31.594c1.289 0 2.526-.412 3.438-1.144.912-.733 1.424-1.726 1.425-2.763z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M247.768 124.833h-17.011v-18.425c0-.816.403-1.597 1.12-2.174.717-.576 1.69-.9 2.704-.9h9.364c1.014 0 1.986.324 2.703.9s1.12 1.358 1.12 2.174z"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M140.036 135.41h77.569v54.074h-77.569z"
        ></path>
        <mask id="path-23-inside-1_5849_115" fill="#fff">
          <path d="M124 190.637a6 6 0 0 1 6-6h96.149a6 6 0 0 1 6 6v3.815a1 1 0 0 1-1 1H125a1 1 0 0 1-1-1z"></path>
        </mask>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="6"
          d="M124 190.637a6 6 0 0 1 6-6h96.149a6 6 0 0 1 6 6v3.815a1 1 0 0 1-1 1H125a1 1 0 0 1-1-1z"
          mask="url(#path-23-inside-1_5849_115)"
        ></path>
        <rect
          width="21.257"
          height="2.983"
          x="129.222"
          y="181.654"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <rect
          width="21.257"
          height="2.983"
          x="129.222"
          y="140.259"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="3"
          d="M133.706 144.742h12.29v35.411h-12.29z"
        ></path>
        <rect
          width="21.257"
          height="2.983"
          x="167.259"
          y="181.654"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <rect
          width="21.257"
          height="2.983"
          x="167.259"
          y="140.259"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="3"
          d="M171.742 144.742h12.29v35.411h-12.29z"
        ></path>
        <rect
          width="21.257"
          height="2.983"
          x="205.299"
          y="181.654"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <rect
          width="21.257"
          height="2.983"
          x="205.299"
          y="140.259"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="2.983"
          rx="1.492"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="3"
          d="M209.783 144.742h12.29v35.411h-12.29zM132.967 132.062h90.215a1.5 1.5 0 0 1 1.5 1.5v5.197h-93.215v-5.196a1.5 1.5 0 0 1 1.5-1.501Z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="m137.052 127.83 33.808-23.894a12.5 12.5 0 0 1 14.429 0l33.808 23.894c1.195.845.597 2.725-.866 2.725h-80.313c-1.463 0-2.061-1.88-.866-2.725"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="m152.219 127.838 18.569-13.321a12.5 12.5 0 0 1 14.573 0l18.568 13.321c1.185.849.584 2.718-.874 2.718h-49.961c-1.458 0-2.059-1.869-.875-2.718"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M182.529 163.089c3.822 1.045 6.308 2.487 9.02 5.235 2.339 2.368 3.494 4.322 4.407 7.453.31 1.062.326 1.583.378 12.694.055 11.506.053 11.591-.279 12.364-.62 1.441-1.847 2.24-3.441 2.24-1.146 0-1.897-.318-2.679-1.134-1.029-1.074-.997-.701-.997-11.508 0-5.392-.043-9.916-.096-10.054-.056-.145-.281-.25-.537-.25s-.481.105-.537.25c-.053.138-.096 4.594-.096 9.904v9.653l-1.185 14.902c-.653 8.196-1.256 15.319-1.341 15.829-.363 2.178-2.033 3.797-4.368 4.232-1.362.254-5.322.096-6.285-.252-1.029-.371-2.375-1.633-2.88-2.701-.395-.835-.444-1.32-1.669-16.562l-1.261-15.702v-9.526c0-5.24-.044-9.639-.096-9.777-.135-.351-.809-.313-1.007.056-.107.201-.163 3.637-.163 10.054 0 10.745.032 10.379-.997 11.452-.859.896-1.579 1.174-2.866 1.107-.957-.049-1.232-.134-1.87-.574-.414-.287-.944-.85-1.187-1.264l-.439-.747-.048-10.681c-.031-6.985.011-11.2.124-12.178.747-6.489 6.038-12.295 13.059-14.329 2.092-.606 2.91-.7 5.538-.634 1.899.047 2.659.137 3.798.448Z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="171.061"
          y="138"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.167"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="3"
          d="m201.138 177.81-7.373 47a2 2 0 0 1-1.976 1.69h-28.578a2 2 0 0 1-1.976-1.69l-7.373-47a2 2 0 0 1 1.976-2.31h43.324a2 2 0 0 1 1.976 2.31Z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.998a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2"
          clipRule="evenodd"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeWidth="2"
          d="M165 195.5h25"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="2"
          d="M156 185h43"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeWidth="2"
          d="M168 201.5h19"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationPolitics;
