import React, { useMemo } from 'react';
import {
  IconButton,
  Collapse,
  Icon,
  Text,
  HStack,
  Spacer,
  Image,
  ButtonGroup,
  Button,
  Skeleton,
  Link,
  VStack,
} from '@wegroup/design-system';
import { formatBytes } from '../../../../../common/src/utils/commonUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  file?: File;
  filename?: string;
  description?: string;
  onRemove: () => void;
  isEditing?: boolean;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  canDownload?: boolean;
}

const SingleDocumentHeader: React.FC<Props> = ({
  file,
  filename,
  description,
  isEditing,
  onEdit,
  onSave,
  onRemove,
  onCancel,
  canDownload,
}) => {
  const { t } = useTranslation();
  // This useMemo is for memory management
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  const preview = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '';
  }, [file]);

  const handleDownload = () => {
    if (!file) return;
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.target = '_blank';
    a.href = url;
    a.setAttribute('download', file.name);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <VStack align="stretch" spacing="0">
      <HStack spacing="2" alignItems="center">
        <Link
          variant="gray"
          href={preview}
          isExternal
          mr="2"
          w="12"
          transition="width 0.5s"
        >
          <Image objectFit="contain" src={preview} h="6" />
        </Link>
        {file ? (
          <VStack align="stretch">
            <HStack>
              <Text textStyle="smallBody" color="black" mr="1">
                {filename || file.name}
              </Text>
              <Text flexShrink="0" textStyle="smallBody" color="text.gray">
                ({formatBytes(file.size)})
              </Text>
            </HStack>
          </VStack>
        ) : (
          <Skeleton h="3" w="24" />
        )}
        <Spacer />
        {isEditing ? (
          <>
            <Button
              flexShrink={0}
              size="sm"
              variant="grayedGhost"
              onClick={onCancel}
            >
              {t('CANCEL')}
            </Button>
            <Button
              flexShrink={0}
              size="sm"
              variant="secondary"
              onClick={onSave}
            >
              {t('SAVE-CHANGES')}
            </Button>
          </>
        ) : (
          <ButtonGroup isDisabled={!file} variant="white" isAttached>
            {onEdit && (
              <IconButton
                size="sm"
                aria-label="edit"
                onClick={onEdit}
                icon={<Icon boxSize="5" name="GeneralActionsEditDocument" />}
              />
            )}
            {canDownload && (
              <IconButton
                color="black"
                borderLeft={onEdit ? 'none' : '1px'}
                borderColor="gray.50"
                size="sm"
                aria-label="download"
                onClick={handleDownload}
                icon={<Icon boxSize="5" name="GeneralActionsDownload" />}
                borderRight="none"
              />
            )}
            <IconButton
              color="danger.main"
              size="sm"
              aria-label="remove"
              onClick={onRemove}
              icon={<Icon boxSize="5" name="GeneralActionsThrash" />}
            />
          </ButtonGroup>
        )}
      </HStack>
      <Collapse in={!!description && !isEditing} unmountOnExit={false}>
        <Text
          textStyle="smallBody"
          color="gray.500"
          mt="3"
          bg="gray.ultraLight"
          p="2"
          border="1px"
          borderColor="gray.50"
          borderRadius="md"
        >
          {description}
        </Text>
      </Collapse>
    </VStack>
  );
};

export default SingleDocumentHeader;
