import { Box } from '@wegroup/design-system';
import React from 'react';
import StaticMap from '../../../../../../../common/src/components/static-map/StaticMap';
import { useAddressBrokerInfo } from '../../../../../../../common/src/hooks/useBrokerDataQueries';
import useMobileBreakpoints from '../../../../../../../common/src/hooks/useMobileBreakpoints';
import LoadingSpinner from '../../../../../../../common/src/components/LoadingSpinner';

const BrokerMap: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useAddressBrokerInfo();
  const { resp: latLong, isLoading } = data || {};
  const { isLaptop } = useMobileBreakpoints();

  if (isLoading) {
    return (
      <Box mx="32" my="16">
        <LoadingSpinner />
      </Box>
    );
  }

  if (!latLong) {
    return null;
  }

  return (
    <StaticMap
      viewPort={{
        latitude: latLong[0],
        longitude: latLong[1],
        zoom: isLaptop ? 16 : 15,
      }}
    />
  );
};

export default BrokerMap;
