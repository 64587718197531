import React, { useMemo } from 'react';
import { Select, FormControl, FormLabel } from '@wegroup/design-system';
import { usePartyAddresses } from '../../api/getPartyAddresses';
import { formatAddress } from '../../../../../../../common/src/utils/commonUtils';
import { useTranslation } from 'react-i18next';
import { Address } from '../../types';

interface Props {
  partyId: string;
  onSelect: (address: Address) => void;
  onClear: () => void;
}

const AddressSearchNLPartyAddresses: React.FC<Props> = ({
  partyId,
  onSelect,
  onClear,
}) => {
  const { t } = useTranslation();
  const { data: existingAddresses } = usePartyAddresses({
    partyId: partyId,
  });

  const addressOptions = useMemo(
    () =>
      existingAddresses?.map((address) => ({
        value: JSON.stringify(address),
        label: formatAddress(address),
      })),
    [existingAddresses],
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      const parsedAddress = JSON.parse(event.target.value);
      onSelect(parsedAddress as Address);
    } else {
      onClear();
    }
  };

  return (
    <FormControl>
      <FormLabel>{t('DESIGN-SYSTEM_ADDRESSINPUT_GROUP_PARTY')}</FormLabel>
      <Select onChange={handleChange}>
        <option selected value="">
          {t('CHOOSE_OPTION')}
        </option>
        {addressOptions?.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default AddressSearchNLPartyAddresses;
