import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersBroker: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.757 9.803c1.752-1.495 3.952-2.106 5.943-2.106h.4c1.99 0 4.191.61 5.944 2.106 1.76 1.503 3.024 3.861 3.2 7.268.037.379.183 2.136-.087 4.269-.271 2.145-.972 4.74-2.697 6.677-.317.37-.634.662-.973.928.671.864 2.637 2.505 8.118 3.875l.005.001c1.53.4 2.705 1.337 3.45 2.702.74 1.353 1.045 3.1.908 5.128v.007l-.001.008c-.089.888-.807 1.637-1.75 1.637h-.088l-.022-.002c-.983-.09-1.732-.91-1.64-1.927.064-.704.06-1.648-.189-2.461-.245-.802-.7-1.41-1.511-1.619-3.676-.928-6.14-2.04-7.796-3.14-.567.597-1.421 1.69-2.143 3.065C27.063 37.676 26.5 39.344 26.5 41a1.5 1.5 0 0 1-3 0c0-1.656-.562-3.324-1.328-4.781-.703-1.338-1.53-2.41-2.097-3.017-1.666 1.054-4.119 2.12-7.71 3.026l-.012.003c-.802.18-1.254.765-1.498 1.565-.248.812-.253 1.772-.189 2.512.092 1.018-.657 1.837-1.64 1.926l-.021.002h-.089c-.96 0-1.633-.76-1.868-1.623l-.013-.047-.003-.048c-.138-1.995.168-3.727.908-5.071.746-1.356 1.92-2.292 3.45-2.693l.007-.001c4.82-1.189 6.92-2.558 7.831-3.468q.128-.127.224-.226a7.2 7.2 0 0 1-1.11-1.04c-1.727-1.938-2.428-4.55-2.7-6.704-.268-2.133-.124-3.885-.085-4.246.175-3.406 1.44-5.764 3.2-7.266m3.618 21.468c.735.81 1.665 2.053 2.453 3.553q.087.165.172.336.085-.171.172-.336c.823-1.567 1.8-2.852 2.55-3.658a7 7 0 0 1-.463-.648c-.71.16-1.44.263-2.226.263h-.266c-.63 0-1.277-.047-1.895-.188a6 6 0 0 1-.497.678m-3.182-14.02v.057q0 .023-.008.115c-.028.376-.119 1.578.022 3.095.164 1.762.635 3.78 1.797 5.128.893 1.011 2.15 1.565 3.83 1.565h.132c1.68 0 2.938-.555 3.832-1.567l.002-.002c1.008-1.13 1.553-2.788 1.814-4.407.259-1.612.225-3.114.13-3.867l-.003-.03v-.086c-.13-2.887-1.244-4.365-2.414-5.142-1.2-.798-2.537-.91-3.16-.91h-.4c-.622 0-1.96.112-3.16.91-1.17.777-2.285 2.255-2.414 5.142"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersBroker;
