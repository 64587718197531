import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm, Controller } from 'react-hook-form';
import {
  Button,
  useBreakpointValue,
  VStack,
  Stack,
  useBoolean,
  FormControl,
  Flex,
  Icon,
  Text,
  Divider,
  HStack,
} from '@wegroup/design-system';
import { getAddress } from '../../api';
import {
  Address,
  AddressSearchNLFormValues,
  addressSearchNLSchema,
} from '../../types/form';
import AddressSearchFooter from '../AddressSearchFooter';
import cogoToast from 'cogo-toast';
import Zipcode from '../manual-address-search/Zipcode';
import HouseNumber from '../manual-address-search/HouseNumber';
import { AddressInputProps } from '../..';
import Country from '../manual-address-search/Country';
import { AddressSearchProps } from '../AddressSearchPicker';
import AddressSearchNLPartyAddresses from './AddressSearchNLPartyAddresses';
import { isCompanyScanFlow } from '../../../../../../../common/src/utils/globalVariables';

interface Props {
  onCountryChange: (country: string) => void;
}

const AddressSearchNL: React.FC<
  React.PropsWithChildren<AddressInputProps & AddressSearchProps & Props>
> = ({
  name,
  value,
  onChange,
  onBlur,
  onSearch,
  onManualModeOn,
  country,
  isManualAllowed,
  error,
  'data-test-id': dataTestId,
  isDisabled,
  onCountryChange,
  partyId,
  supportedCountries,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useBoolean();
  const showOnlyCountryFlag = useBreakpointValue({ base: false, tablet: true });
  const { control, handleSubmit } = useForm<AddressSearchNLFormValues>({
    resolver: yupResolver(addressSearchNLSchema),
    defaultValues: {
      zipCode: value?.zipcode?.toString().toUpperCase() || undefined,
      houseNr: value?.housenr || undefined,
    },
  });

  const onError = () => {
    cogoToast.error(t('DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-NOT-FOUND'), {
      hideAfter: 5,
    });
  };

  const searchAddress = async (values: FieldValues) => {
    const { zipCode, houseNr } = values;

    setIsLoading.on();

    try {
      const response = await getAddress({
        query: `${zipCode} ${houseNr}`,
        countryCode: 'NL',
      });

      if (response[0]) {
        onSearch?.(response[0]);
        onChange(response[0]);
        onManualModeOn();
      } else {
        onError();
      }
    } catch {
      onError();
    }

    setIsLoading.off();
  };

  const handleFooterClick = () => {
    onManualModeOn();
    onChange({ country_code: country });
  };

  const handleAddressSelect = (address: Address) => {
    onChange(address);
    onManualModeOn();
  };

  return (
    <FormControl
      as={VStack}
      alignItems="stretch"
      w="100%"
      isInvalid={!!error}
      data-test-id={dataTestId}
    >
      {partyId && isCompanyScanFlow() && (
        <>
          <AddressSearchNLPartyAddresses
            partyId={partyId}
            onSelect={handleAddressSelect}
            onClear={() => onChange({ country_code: country })}
          />
          <HStack py="2">
            <Divider color="gray.200" />
            <Text color="gray.200" fontSize="md" px="2">
              {t('OR')}
            </Text>
            <Divider color="gray.200" />
          </HStack>
        </>
      )}
      <Stack
        spacing="2"
        direction={{
          base: 'column',
          tablet: 'row',
        }}
      >
        <Country
          value="NL"
          onChange={(_country) => onCountryChange(_country || 'be')}
          onlyFlag={showOnlyCountryFlag}
          supportedCountries={supportedCountries}
        />
        <Controller
          name="zipCode"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Zipcode
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error}
              isDisabled={isDisabled}
            />
          )}
        />

        <Controller
          name="houseNr"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <HouseNumber
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error}
              isDisabled={isDisabled}
            />
          )}
        />
      </Stack>
      <AddressSearchFooter
        label={t('FLOWS_ADDRESS-SEARCH_NL_TIP')}
        isManualAllowed={isManualAllowed}
        onClick={handleFooterClick}
      />
      <Flex justify="flex-end">
        <Button
          mt="2"
          leftIcon={<Icon name="GeneralActionsSearch" />}
          isLoading={isLoading}
          w={{ base: '100%', tablet: '40' }}
          onClick={handleSubmit(searchAddress)}
          isDisabled={isDisabled}
        >
          {t('Search')}
        </Button>
      </Flex>
    </FormControl>
  );
};

export default AddressSearchNL;
