import React from 'react';
import {
  BoxProps,
  Icon,
  Spacer,
  HStack,
  Box,
  Text,
  useTheme,
  Center,
  Image,
} from '@wegroup/design-system';

interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
  img?: string;
}

interface Props {
  option: Option;
  selected?: boolean;
  onClick?: () => void;
  hideIcon?: boolean;
  isDisabled?: boolean;
}

const SearchPickerOption: React.FC<React.PropsWithChildren<Props>> = ({
  option,
  selected = false,
  onClick,
  hideIcon,
  isDisabled,
}) => {
  const Icon = option.icon;
  const img = option.img;
  const { colors } = useTheme();

  return (
    <HStack
      onClick={isDisabled ? undefined : onClick}
      spacing="3"
      p="3"
      key={option.value}
      bg={isDisabled ? 'gray.50' : 'white'}
      _hover={{ bg: isDisabled ? 'default' : 'gray.ultraLight' }}
      cursor={isDisabled ? 'default' : 'pointer'}
      borderBottom="1px"
      borderColor="gray.strokeAndBg"
      _last={{ borderBottom: 'none' }}
      data-test-id={`COMPONENT_SEARCH-PICKER_SINGLE-OPTION`}
    >
      {!hideIcon && (
        <Center
          w="8"
          h="8"
          borderRadius="lg"
          bg={selected && !img ? 'primary.500' : 'gray.ultraLight'}
          transition="0.1s"
          p="1.5"
          flexShrink={0}
          sx={{ svg: { w: '100%', h: '100%', transition: '0.1s' } }}
        >
          {Icon ? (
            <Icon color={selected ? colors.white : colors.gray[200]} />
          ) : img ? (
            <Image src={img} />
          ) : (
            <Box
              borderRadius="full"
              w="100%"
              h="100%"
              border="2px solid"
              borderColor={selected ? 'white' : 'gray.200'}
            />
          )}
        </Center>
      )}
      <Text
        textStyle={selected ? 'normalBodyMedium' : 'normalBody'}
        transition="0.1s"
        color={selected ? 'black' : 'gray.400'}
      >
        {option.label}
      </Text>
      <Spacer />
      <Checkbox
        pointerEvents="none"
        isChecked={isDisabled || selected}
        isDisabled={isDisabled}
      />
    </HStack>
  );
};

interface CheckBoxProps {
  isChecked: boolean;
  isDisabled?: boolean;
}

const Checkbox: React.FC<CheckBoxProps & Omit<BoxProps, 'onChange'>> = ({
  isChecked,
  isDisabled,
  ...props
}) => {
  return (
    <Center
      borderRadius="sm"
      border="2px"
      cursor="pointer"
      flexShrink="0"
      borderColor={isChecked && !isDisabled ? 'primary.500' : 'gray.100'}
      w="4"
      h="4"
      bg={isDisabled ? 'gray.100' : isChecked ? 'primary.500' : 'white'}
      _hover={{
        borderColor: isChecked ? 'primary.600' : 'primary.500',
        bg: isChecked ? 'primary.600' : 'white',
      }}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      {...props}
    >
      {isChecked && (
        <Icon
          name="GeneralStatusSuccessCheckmark"
          boxSize="4"
          color={isDisabled ? 'gray.300' : 'white'}
        />
      )}
    </Center>
  );
};

export default SearchPickerOption;
