import React, { ChangeEvent, ReactElement, useRef } from 'react';
import {
  Link,
  Box,
  useDisclosure,
  VStack,
  BoxProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
} from '@wegroup/design-system';
import SearchInput from '../SearchPickerInputField';
import SearchPickerList from '../SearchPickerList/SearchPickerList';
import useElementBounds from '../../../../../common/src/hooks/useElementBounds';

export interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
  img?: string;
  isDisabled?: boolean;
}

export interface Category {
  label: string;
  option_keys: string[];
}
export interface SearchPickerProps {
  placeholder?: string;
  options?: Option[];
  categories?: Category[];
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  extraActionLabel?: string;
  extraActionOnClick?: () => void;
  noResultsText?: string;
  hideIcons?: boolean;
  withSelectAll?: boolean;
  isDisabled?: boolean;
  showIcon?: boolean;
  customWidth?: string;
  icon?: ReactElement;
}

const SearchPickerInput: React.FC<
  React.PropsWithChildren<SearchPickerProps & BoxProps>
> = ({
  placeholder,
  options = [],
  categories = [],
  searchValue,
  onChange,
  selectedOptions,
  setSelectedOptions,
  extraActionLabel,
  extraActionOnClick,
  noResultsText,
  hideIcons,
  withSelectAll,
  isDisabled,
  showIcon,
  customWidth,
  icon,
  ...props
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const { setRef, width } = useElementBounds();

  const handleClick = () => {
    if (!isDisabled) onOpen();
  };

  return (
    <Box position="relative">
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
        isLazy
        autoFocus={false}
      >
        <PopoverTrigger>
          <Box
            ref={setRef}
            tabIndex={1}
            width={customWidth}
            onClick={handleClick}
          >
            <SearchInput
              ref={inputRef}
              value={searchValue}
              selectedOptions={selectedOptions}
              onChangeSelected={(option) =>
                setSelectedOptions(
                  selectedOptions.filter((o) => o.value !== option.value),
                )
              }
              onChange={onChange}
              onClosePopover={onClose}
              boxProps={props}
              placeholder={placeholder}
              isDisabled={isDisabled}
              showIcon={showIcon}
              icon={icon}
            />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            w={customWidth || width}
            as={VStack}
            align="stretch"
            // Chakra UI has a bug where the zIndex is incorrect for a popover
            rootProps={{ zIndex: 'popover', width: customWidth }}
          >
            <Box
              w="100%"
              border="1px solid"
              borderRadius="5px"
              borderColor="gray.strokeAndBg"
            >
              <SearchPickerList
                inputRef={inputRef}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                options={options}
                categories={categories}
                noResultsText={noResultsText}
                hideIcons={hideIcons}
                withSelectAll={withSelectAll}
                onClosePopover={onClose}
              />
            </Box>
            {extraActionLabel && (
              <Box p="4" borderTop="1px" borderColor="gray.strokeAndBg">
                <Link variant="gray" fontSize="sm" onClick={extraActionOnClick}>
                  {extraActionLabel}
                </Link>
              </Box>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default SearchPickerInput;
