import React, { ReactElement } from 'react';
import {
  VStack,
  Icon,
  Heading,
  Box,
  Flex,
  AspectRatio,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@wegroup/design-system';
import IllustrationPlaceholder from '../../assets/illustrations-rounded/IllustrationPlaceholder';

export enum IllustratedButtonType {
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
}

export interface Props {
  isChecked: boolean;
  disabled?: boolean;
  Illustration?: ReactElement;
  input?: ReactElement;
  inputBox?: ReactElement;
  dataTestId?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  description?: string;
}

const IllustratedButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  isChecked,
  disabled = false,
  Illustration,
  children,
  input,
  inputBox,
  dataTestId = 'DESIGN-SYSTEM_ILLUSTRATED-BUTTON',
  isDisabled,
  description,
  ...rest
}) => {
  const getTextColor = (): string => {
    if (isChecked) {
      if (disabled) return 'primary.200';
      return 'primary.500';
    } else if (disabled) {
      return 'gray.200';
    }
    return 'black';
  };

  return (
    <Box position="relative" w="100%" maxW="210px">
      <AspectRatio w="100%" maxW="210px" ratio={1}>
        <Flex
          onClick={onClick}
          as="label"
          bgColor="white"
          cursor={isDisabled ? 'default' : 'pointer'}
          p={{ base: '2', tabletS: '5' }}
          borderColor="gray.50"
          borderRadius="8px"
          borderStyle="solid"
          borderWidth="1px"
          w="100%"
          h="100%"
          boxShadow="level2"
          userSelect="none"
          _checked={{
            borderColor: disabled ? 'primary.200' : 'primary.500',
            borderWidth: '2px',
            boxShadow: 'level4',
            p: { base: '7px', tabletS: '19px' },
          }}
          _disabled={{
            _hover: {
              boxShadow: isChecked ? 'level4' : 'level2',
            },
          }}
          _hover={{
            boxShadow: 'level4',
          }}
          transition="box-shadow .1s, border-color .1s"
          position="relative"
          data-test-id={dataTestId}
          {...rest}
        >
          {input}
          <VStack
            alignItems="stretch"
            flexDir="column"
            justifyContent="space-between"
            w="100%"
            h="100%"
          >
            <Box
              w="100%"
              h={{
                base: '100%',
                mobileS: '50%',
                mobileL: '75%',
                tabletS: '90%',
              }}
              sx={{
                svg: { w: '100%', h: '100%' },
              }}
              opacity={disabled ? '0.6' : '1'}
              transition="0.2s"
            >
              {Illustration ? (
                Illustration
              ) : (
                <IllustrationPlaceholder isActive={isChecked} />
              )}
            </Box>
            <Heading
              as="h4"
              textAlign="center"
              textStyle="smallBody"
              color={getTextColor()}
              fontSize={{ base: 'md', mobileL: 'xl' }}
            >
              {children}
            </Heading>
          </VStack>
          <Box
            position="absolute"
            right={isChecked ? '11px' : '12px'}
            top={isChecked ? '11px' : '12px'}
          >
            {inputBox}
          </Box>
        </Flex>
      </AspectRatio>
      {description && (
        <Box position="absolute" left="11px" top="11px">
          <Popover trigger="hover" placement="bottom">
            <PopoverTrigger>
              <Box>
                <Icon name="GeneralActionsQuestion" />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              bg="gray.700"
              color="white"
              textStyle="smallBodyMedium"
              lineHeight="5"
              // This is a workaround to prevent the popover from causing a horizontal scrollbar
              // https://github.com/chakra-ui/chakra-ui/issues/4109
              rootProps={{ style: { transform: 'scale(0)' } }}
            >
              <PopoverArrow bg="gray.700" color="white" />
              <PopoverBody
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </Box>
  );
};

export default IllustratedButton;
