import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, HStack, Link, Icon } from '@wegroup/design-system';
import { Option } from './SearchPickerList';

interface Props {
  options: Option[];
  selectedOptions: Option[];
  withSelectAll?: boolean;
  withCloseButton?: boolean;
  setSelectedOptions: (options: Option[]) => void;
  onClose?: () => void;
}

const SearchPickerHeader: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  selectedOptions,
  withSelectAll,
  withCloseButton = true,
  setSelectedOptions,
  onClose,
}) => {
  const { t } = useTranslation();

  const selectAll = () => {
    setSelectedOptions(options);
  };

  const deselectAll = () => {
    setSelectedOptions([]);
  };

  const isAllSelected = selectedOptions.length === options.length;

  return (
    <HStack
      justifyContent="flex-end"
      position="sticky"
      zIndex="1"
      top="0"
      w="100%"
      px="3"
      pt="3"
      pb="2"
      bg="white"
      spacing="4"
    >
      {withSelectAll && (
        <Link
          variant="gray"
          onClick={isAllSelected ? deselectAll : selectAll}
          data-test-id="FLOWS_INPUT-COMPONENT_MULTI-SELECT_SELECT-ALL-BUTTON"
        >
          {isAllSelected ? t('Deselect all') : t('Select all')}
        </Link>
      )}
      {withCloseButton && (
        <IconButton
          icon={<Icon name="GeneralActionsCloseOrRemove" />}
          aria-label="Close search picker"
          variant="grayedGhost"
          size="xs"
          onClick={onClose}
          data-test-id="FLOWS_INPUT-COMPONENT_MULTI-SELECT_CLOSE-BUTTON"
        />
      )}
    </HStack>
  );
};

export default SearchPickerHeader;
