import {
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  placeholder?: string;
  value?: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const SearchButton: React.FC<Props> = ({
  onClick,
  isDisabled,
  value,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <InputGroup onClick={() => !isDisabled && onClick()}>
      <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
        <Icon name="GeneralActionsSearch" color="gray.300" />
      </InputLeftElement>
      <Input
        cursor="pointer"
        sx={{ caretColor: 'transparent' }}
        isDisabled={isDisabled}
        placeholder={placeholder || t('SEARCH')}
        value={value || ''}
        {...props}
      />
    </InputGroup>
  );
};

export default SearchButton;
