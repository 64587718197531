import { Template } from '../packages/campaigns/src/types/campaignTypes';
import { ThemeName } from '../packages/design-system/src/theme/themes';
import { Subject } from './RiskAnalaysis';
export interface Campaign {
  id: string;
  created_at: string;
  type: TYPE | null;
  active: boolean;
  insurances: Insurance[];
  name: string;
  display_name?: string;
  extend: EXTEND;
  language: LANGUAGE;
  end_date?: string;
  start_date: string;
  theme: Theme;
  logo?: string | null;
  url: string;
  short_id: string;
  assigned_to: string | null;
  assigned_to_name: string | null;
  created_by: string;
  distribution_id: string;
  google_analytics_tracking_id?: string | null;
  flow_version: FLOW_VERSION;
  specifications: Specifications;
  assistant_name: string;
  assistant_avatar: string | null;
  send_reminders: boolean;
  flat_commission_discount?: number;
  status: STATUS;
  targets: Target[];
  notifications: string[];
  risk_analysis_subjects?: Subject[];
  subjects?: string[];
  code?: number;
  template_id: string; // THIS KEY TEMPORARY
  /** @deprecated */
  flow_id: string;
  shared?: boolean;
  template?: Template;
  dns_prefix?: string;
  show_offers?: boolean;
  show_acknowledgements?: boolean;
  lead_provider_id?: string;
}
export interface Theme {
  name: ThemeName;
  primary_color: string;
  secondary_color: string;
}
export interface Insurance {
  insurance_type: string;
  insurance_company: string;
  vk_applied: number;
}

export type InsuranceTypes =
  | 'CAR'
  | 'RESIDENCE'
  | 'FAMILY'
  | 'LEGAL'
  | 'FUNERAL';

export enum InsuranceTypesIconMeta {
  'CAR' = 'GeneralRiskObjectsCar',
  'RESIDENCE' = 'GeneralRiskObjectsHome',
  'FAMILY' = 'GeneralNavigationFamily',
  'LEGAL' = 'GeneralRiskObjectsLegal',
  'FUNERAL' = 'GeneralRiskObjectsFuneral',
}
export interface Specifications {
  car_brand_filters: string[];
}

export interface Target {
  conversation_id: string;
  email: string;
  name: string;
  party_id: string;
  risk_object_revision_ids: string[];
  type: 'CUSTOMER' | 'COMPANY';
  url: string;
}

//
// ─── ENUMS ──────────────────────────────────────────────────────────────────────
//

export enum TYPE {
  EMAIL = 'EMAIL',
  WEBSITE = 'WEBSITE',
}
export enum AUDIENCE_TYPE {
  BROAD_AUDIENCE = 'BROAD_AUDIENCE',
  KNOWN_TARGETS = 'KNOWN_TARGETS',
}
export enum EXTEND {
  LEAD = 'LEAD',
  INTERNAL_OFFER = 'INTERNAL_OFFER',
  EXTERNAL_OFFER = 'EXTERNAL_OFFER',
  ONLINE_INSURANCE = 'ONLINE_INSURANCE',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  COMPANY_SCAN = 'COMPANY_SCAN',
}

export enum LANGUAGE {
  FR = 'FR',
  NL = 'NL',
  EN = 'EN',
  'NL-NL' = 'NL-NL',
}
export enum FLOW_VERSION {
  V1 = 'V1',
  V2 = 'V2',
}
export enum FLOW_TYPE {
  lead = 'lead',
  quote = 'quote',
  offer = 'offer',
  contract = 'contract',
  risk_analysis = 'risk_analysis',
}
export enum STATUS {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  EXPIRES = 'EXPIRES',
  MAILS_SENT = 'MAILS_SENT',
  HAS_ENDED = 'HAS_ENDED',
}

export enum INSURANCE_TYPE {
  CAR = 'CAR',
  FAMILY = 'FAMILY',
  RESIDENCE = 'RESIDENCE',
  TEACHER = 'TEACHER',
  TWO_WHEELER = 'TWO_WHEELER',
  LANDLORD = 'LANDLORD',
  EBIKE = 'EBIKE',
  LEGAL = 'LEGAL',
  FUNERAL = 'FUNERAL',
  PARTY_GROUP = 'PARTY_GROUP',
}
