import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPersonInfo: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5848_17"
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill="#C0C2C9" d="M0-.002h356v228H0z"></path>
      </mask>
      <g mask="url(#mask0_5848_17)">
        <path
          fill={colors.primary[50]}
          d="M337 160.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M87.368 244.786a4.904 4.904 0 0 0 3.976 5.682l140.059 24.697a4.91 4.91 0 0 0 5.68-3.981l27.677-156.963-33.812-48.275-103.839-18.31a4.9 4.9 0 0 0-4.986 2.194 4.9 4.9 0 0 0-.693 1.783z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M206.678 178.59c3.583 1.693 5.78 3.545 7.974 6.721 1.892 2.739 2.69 4.863 3.046 8.105.121 1.101.046 1.616-1.832 12.567-1.944 11.341-1.961 11.425-2.422 12.128-.861 1.311-2.208 1.885-3.778 1.608-1.128-.199-1.812-.642-2.441-1.581-.827-1.237-.86-.864 1.017-11.507.936-5.31 1.679-9.773 1.651-9.918-.03-.153-.233-.295-.485-.339s-.492.019-.573.153c-.076.126-.892 4.508-1.814 9.736l-1.676 9.507-3.756 14.469c-2.065 7.959-3.896 14.869-4.068 15.356-.736 2.082-2.662 3.386-5.037 3.41-1.386.014-5.258-.83-6.146-1.34-.949-.544-2.055-2.02-2.367-3.16-.244-.891-.208-1.377 1.233-16.6l1.484-15.682 1.654-9.382c.91-5.16 1.631-9.5 1.603-9.645-.071-.369-.742-.449-1-.12-.142.18-.793 3.554-1.908 9.873-1.866 10.582-1.77 10.227-2.97 11.105-1.002.734-1.759.882-3.015.593-.933-.215-1.19-.346-1.741-.891-.359-.354-.782-1.001-.95-1.45l-.302-.812 1.807-10.527c1.182-6.885 1.956-11.028 2.237-11.971 1.862-6.261 8.081-11.06 15.348-11.844 2.167-.234 2.987-.184 5.565.337 1.861.376 2.594.597 3.662 1.101Z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="199.741"
          y="151.891"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.167"
          transform="rotate(10 199.741 151.891)"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="m264.763 114.221-36.541-6.443a4.58 4.58 0 0 1-3.715-5.305l6.441-36.528z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="m123.261 143.461 30.127 5.312M125.828 128.906l49.149 8.667M128.348 114.617l103.255 18.207"
        ></path>
        <rect
          width="72.089"
          height="22.922"
          x="136.156"
          y="68.606"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(10 136.156 68.606)"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.998a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationPersonInfo;
