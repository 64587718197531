import i18n from '../../../../i18n';

export const phoneChangeConversion = (
  value: string,
  country: { dialCode: string },
): string => {
  value = value.replace(' ', '');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }
  return value;
};

export const phoneBlurConversion = (
  value: string,
  country: { dialCode: string },
): string => {
  value = value.replace('+', '').split(' ').join('');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }
  return value;
};

export const getProFlowRouteNaming = (type: string): string => {
  switch (type.toLowerCase()) {
    case 'residence':
      return 'home';
    default:
      return type;
  }
};

export const toEuro = (amount: number, currency = 'EUR'): string => {
  const currencyToLocaleMap: Record<string, string> = {
    USD: 'en-US',
    ANG: 'nl-CW',
    EUR: 'nl-BE',
  };

  const locale = currencyToLocaleMap[currency];
  if (!locale) {
    console.warn(`Locale not found for currency: ${currency}`);
    return `${currency} ${amount}`;
  }

  if (isNaN(amount)) {
    console.error(`Invalid amount: ${amount}`);
    return '-';
  }

  let formattedAmount = Number(amount).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
  });

  if (currency === 'ANG') {
    formattedAmount = formattedAmount.replace(/NAf\./g, 'ƒ');
  }

  return formattedAmount;
};

export const toPercentage = (amount: number, fractionDigits = 2): string =>
  Intl.NumberFormat('nl-BE', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Math.abs(amount));

export const arrayToWords = (listOfWords?: string[]): string =>
  listOfWords?.slice(0, listOfWords.length - 1).join(', ') +
  `, ${i18n.t('and')} ` +
  listOfWords?.slice(-1);
