import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDriverLicence: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      {' '}
      <mask
        id="mask0_5846_1023"
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0z"></path>
      </mask>
      <g mask="url(#mask0_5846_1023)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2"
          clipRule="evenodd"
        ></path>
        <rect
          width="247"
          height="151.381"
          x="54"
          y="49.813"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M205.124 157.223c-14.838 15.175 13.565 43.546 27.116 12.536 3.235 18.474 20.666 19.793 25.824 2.639 4.548 12.476 10.19 13.844 21.951 13.195"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M53.839 90.565h246.403"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M121.401 69.108a.94.94 0 0 0-.671-.55l-12.35-2.623a.96.96 0 0 1-.55-.353l-2.503-3.31a.48.48 0 0 0-.284-.181l-2.167-.387a42.6 42.6 0 0 0-15.893.172l-1.1.223a.95.95 0 0 0-.542.319c-.912 1.057-4.48 5.306-4.48 6.243s.55 5.074.807 6.682a.94.94 0 0 0 .912.783h3.002a4.223 4.223 0 0 0 8.385 0h15.067a4.22 4.22 0 0 0 4.192 3.72 4.22 4.22 0 0 0 4.193-3.72h3.044a.95.95 0 0 0 .92-.714c.34-1.3.592-2.622.757-3.956a9.5 9.5 0 0 0-.739-2.348M89.77 77.992a2.34 2.34 0 0 1-2.296-1.866 3 3 0 0 1 0-.465 2.348 2.348 0 0 1 4.686 0 3 3 0 0 1-.042.465 2.35 2.35 0 0 1-2.348 1.866m23.461 0a2.35 2.35 0 0 1-2.271-1.866 3 3 0 0 1-.043-.465 2.349 2.349 0 0 1 4.687 0q.02.233 0 .465a2.34 2.34 0 0 1-2.373 1.866"
        ></path>
        <rect
          width="55.762"
          height="76.908"
          x="75.147"
          y="106.816"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <mask
          id="mask1_5846_1023"
          width="60"
          height="82"
          x="73"
          y="104"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
        >
          <rect
            width="55.762"
            height="76.908"
            x="75.144"
            y="106.816"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_5846_1023)"
        >
          <circle cx="102.566" cy="182.046" r="27.421" fill="#fff"></circle>
          <rect
            width="25.421"
            height="31.857"
            x="89.855"
            y="114.332"
            fill="#fff"
            rx="12.711"
          ></rect>
          <rect
            width="55.762"
            height="76.908"
            x="75.144"
            y="106.816"
            rx="2"
          ></rect>
        </g>
        <rect
          width="26.341"
          height="10.711"
          x="151.455"
          y="106.816"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <rect
          width="26.341"
          height="10.711"
          x="187.312"
          y="106.816"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <rect
          width="26.341"
          height="10.711"
          x="241.557"
          y="106.816"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <rect
          width="26.341"
          height="10.711"
          x="151.455"
          y="127.963"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <rect
          width="35.535"
          height="10.711"
          x="187.312"
          y="127.963"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <rect
          width="23.582"
          height="10.711"
          x="232.363"
          y="127.963"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.355"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="3"
          d="M151 72h126"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationDriverLicence;
