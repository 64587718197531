import React, { forwardRef, PropsWithChildren } from 'react';
// import { isMobile } from 'react-device-detect';

import { SearchSelectProps } from './types';

import SearchSelectInput from './SearchSelectInput';

const SearchSelect = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SearchSelectProps>
>((props, ref) => {
  //  Destructuring props to filter chakra styling props
  const {
    name,
    data,
    clearable,
    isLoading,
    prefilled,
    error,
    value,
    onChange,
    'data-test-id': dataTestId,
    placeholder,
    extraStyles,
    dataFetcher,
    onSearchChange,
    nothingFound,
    isDisabled,
    isDataFilterDisabled,
    leftSection,
    comboboxProps,
    searchable = true,
    ...chakraProps
  } = props;

  const mantineProps = {
    name,
    data,
    clearable,
    isLoading,
    isDisabled,
    prefilled,
    searchable,
    error,
    value,
    onChange,
    'data-test-id': dataTestId,
    placeholder,
    extraStyles,
    dataFetcher,
    onSearchChange,
    nothingFound,
    isDataFilterDisabled,
    leftSection,
    comboboxProps,
  };

  // Drawer for mobile

  // const openDrawer = (
  //   e:
  //     | React.TouchEvent<HTMLInputElement>
  //     | React.FocusEvent<HTMLInputElement, Element>,
  // ) => {
  //   // Stops dropdown from opening
  //   e.preventDefault();
  //   e.stopPropagation();

  //   // Open drawer
  //   // onOpen();
  // };

  // const handleOnFocusCapture: FocusEventHandler<HTMLInputElement> = (e) => {
  //   e.target.blur(); // Unfocus the element
  //   openDrawer(e);
  // };

  return (
    <>
      {/* Mobile */}
      {/* {isMobile && (
        <SearchSelectDrawer
          isOpen={isOpen}
          onClose={onClose}
          chakraProps={chakraProps}
          {...mantineProps}
        />
      )} */}

      {/* Desktop */}
      <SearchSelectInput
        ref={ref}
        // onFocusCapture={isMobile ? handleOnFocusCapture : undefined}
        chakraProps={chakraProps}
        {...mantineProps}
      />
    </>
  );
});

export default SearchSelect;
